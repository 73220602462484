<template>
  <div class="fias">
    <esmp-select
      ref="list"
      class="fias__search"
      placeholder="Поиск адреса"
      filterable
      clearable
      :remote-method="debouncedSearch"
      :loading="loading"
      :show-search-results="showSearchResults"
      @on-select="selectAddress"
      @on-query-change="setQuery"
      @scroll="scrollAddressList"
    >
      <esmp-select-option
        v-for="address in formattedAddressList"
        :key="address.id"
        :value="address.fullName"
        :tag="address.id"
        v-html="address.formattedFullName"
      />
    </esmp-select>
    <div class="fias__selected-address" v-if="selectedAddressName">
      Выбранный адрес:
      <span>{{ selectedAddressName }}</span>
      <esmp-button
        icon="close-circle"
        view="function"
        size="small"
        @click="$refs.list.reset"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { SEARCH_DELAY, SEARCH_RESULTS_PER_PAGE } from '@/constants/search';

export default {
  name: 'Fias',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      query: '',
      currentPage: 1,
      pageCount: null,
      addressesList: [],
      loading: false,
      selectedAddressName: '',
      isLoadingCurrentSection: false,
      showSearchResults: false,
    };
  },
  watch: {
    query(val) {
      this.currentPage = 1;
      if (!val) {
        this.clearSelectedAddress();
      }
    },
  },
  computed: {
    selectedAddressId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    formattedAddressList() {
      return this.addressesList.map((address) => {
        let formattedFullName = address.fullName;
        const wordsList = this.query.split(' ');
        wordsList.forEach((word) => {
          const regEx = new RegExp(word, 'ig');
          formattedFullName = formattedFullName.replace(regEx, `<span class="fias__match-address">${word}</span>`);
        });
        return {
          ...address,
          formattedFullName,
        };
      });
    },
  },
  methods: {
    getAddresses() {
      this.loading = true;
      this.isLoadingCurrentSection = true;
      this.showSearchResults = true;

      const dropDownList = this.$refs.list.$refs.dropdown.$el.querySelector('.esmp-select-dropdown-list');
      const scrollHeight = dropDownList ? dropDownList.scrollHeight : 0;

      this.$API.fias.getLocations({
        query: this.query,
        page: this.currentPage - 1,
        size: SEARCH_RESULTS_PER_PAGE,
      }).then(({ data }) => {
        this.pageCount = data.totalPages;
        this.addressesList.push(...data.content);
      }).finally(() => {
        this.loading = false;
        if (!this.addressesList.length) {
          this.showSearchResults = false;
          this.$EsmpNotify.$show('Адрес не найден', 'error');
        }
        this.$nextTick(() => {
          this.$refs.list.$refs.dropdown.$el.scrollTop = scrollHeight;
          this.isLoadingCurrentSection = false;
        });
      });
      return true;
    },
    selectAddress(address) {
      this.selectedAddressId = address.tag;
      this.selectedAddressName = address.value;
      this.clearAddressList();
    },
    setQuery(val) {
      this.query = val;
      this.clearAddressList();
    },
    clearAddressList() {
      this.addressesList = [];
      this.showSearchResults = false;
    },
    scrollAddressList(e) {
      const { scrollHeight, scrollTop, offsetHeight } = e.target;
      if (
        scrollTop + offsetHeight === scrollHeight
        && this.currentPage + 1 < this.pageCount && !this.isLoadingCurrentSection
      ) {
        this.currentPage += 1;
        this.getAddresses();
      }
    },
    clearSelectedAddress() {
      this.selectedAddressId = null;
      this.selectedAddressName = '';
    },
  },
  created() {
    this.debouncedSearch = debounce(this.getAddresses, SEARCH_DELAY);
  },
};
</script>

<style lang="scss">
.fias {
  padding: $base-gutter * 1.5 $base-gutter * 2;
  border-radius: $base-border-radius * 1.5;
  border: 1px solid $color-grayscale-10;
  @include for-size(phone-portrait-down) {
    padding: calc(#{$base-gutter} / 2) $base-gutter;
  }

  &__match-address {
    color: $primary-color-hover;
  }

  &__search {
    margin-bottom: $base-gutter;
  }

  &__selected-address {
    font-size: $font-size-base;
    display: flex;
    align-items: center;

    span {
      color: $primary-color-hover;
      padding: 0 calc(#{$base-gutter} / 4);
    }

    .esmp-button {
      .esmp-svg-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
